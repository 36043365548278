<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_export_solInte"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Listado Solicitudes Internas
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0 pt-1">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="gerente_area" class="col-md-5"
                      >Gerente Área</label
                    >
                    <v-select
                      :class="[
                        /* $v.filtros.gerente_area_id.$invalid ? 'is-invalid' : 'is-valid', */
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="filtros.gerente_area_id"
                      placeholder="Gerente Area"
                      label="nombres"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.gerente_areas"
                      :filterable="true"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="bloque" class="col-md-5">Bloque</label>
                    <v-select
                      :class="[
                        /* $v.filtros.bloque_id.$invalid ? 'is-invalid' : 'is-valid', */
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="filtros.bloque_id"
                      placeholder="Bloques"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.bloques"
                      :filterable="true"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="sitio" class="col-md-5">Sitio</label>
                    <v-select
                      :class="[
                        /* $v.filtros.sitio_id.$invalid ? 'is-invalid' : 'is-valid', */
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                      ]"
                      v-model="filtros.sitio_id"
                      placeholder="Sitios"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.sitios"
                      :filterable="true"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="fecha_inicial" class="col-md-5"
                      >Fecha Inicial</label
                    >
                    <input
                      type="date"
                      id="fecha_inicial"
                      v-model="filtros.fecha_inicial"
                      class="form-control form-control-sm col-md-7"
                      :class="
                        $v.filtros.fecha_inicial.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validaFechasForm()"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="fecha_final" class="col-md-5"
                      >Fecha Final</label
                    >
                    <input
                      type="date"
                      id="fecha_final"
                      v-model="filtros.fecha_final"
                      class="form-control form-control-sm col-md-7"
                      :class="
                        $v.filtros.fecha_final.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="validaFechasForm()"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="tipo_solicitud" class="col-md-5"
                      >Tipo Solicitud</label
                    >
                    <select
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.tipo_solicitud"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo in listasForms.tipo_solicitudes"
                        :key="tipo.numeracion"
                        :value="tipo.numeracion"
                      >
                        {{ tipo.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="usuario1"
                              v-model="form.usuario"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="usuario1"
                              >Usuario</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="gerente_area"
                              v-model="form.gerente_area"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="gerente_area"
                              >Gerente Área</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="vicepresidente"
                              v-model="form.vicepresidente"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="vicepresidente"
                              >Vicepresidente</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_solicitud"
                              v-model="form.tipo_solicitud"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_solicitud"
                              >Tipo Solicitud</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="tipo_emergencia"
                              v-model="form.tipo_emergencia"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="tipo_emergencia"
                              >Tipo Emergencia</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="fecha"
                              v-model="form.fecha"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="fecha"
                              >Fecha</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="sitio"
                              v-model="form.sitio"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="sitio"
                              >Sitio</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="bloque"
                              v-model="form.bloque"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="bloque"
                              >Bloque</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="justificacion"
                              v-model="form.justificacion"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label"
                              for="justificacion"
                              >Justificación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="obvs"
                              v-model="form.obvs"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="obvs"
                              >Observación</label
                            >
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="estado1"
                              v-model="form.estado"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="estado1"
                              >Estado</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="
                  !$v.filtros.$invalid &&
                    $store.getters.can(
                      'funcionariofrontera.csSolicitudInterna.export'
                    )
                "
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
export default {
  name: "CsSolicitudesInternasExport", //llegada tambien
  components: { Loading, vSelect },
  data() {
    return {
      cargando: false,
      form: {
        id: true,
        usuario: true,
        gerente_area: true,
        vicepresidente: true,
        tipo_solicitud: true,
        tipo_emergencia: true,
        fecha: true,
        sitio: true,
        bloque: true,
        justificacion: true,
        obvs: true,
        estado: true,
      },
      filtros: {
        gerente_area_id: null,
        bloque_id: null,
        sitio_id: null,
        fecha_inicial: null,
        fecha_final: null,
        tipo_solicitud: null,
      },

      listasForms: {
        gerente_areas: [],
        bloques: [],
        sitios: [],
        tipo_solicitudes: [],
      },
    };
  },

  validations: {
    filtros: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
    },
  },

  methods: {
    getGerenteArea() {
      axios
        .get("/api/admin/funcionarios/lista", {
          params: {
            gerente_area: 1,
          },
        })
        .then((response) => {
          this.listasForms.gerente_areas = response.data;
        });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    getSitios() {
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
      });
    },

    getTipoSolicitudes() {
      axios.get("/api/lista/127").then((response) => {
        this.listasForms.tipo_solicitudes = response.data;
      });
    },

    validaFechasForm() {
      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        const fecha_menor = new Date(this.filtros.fecha_inicial);
        const fecha_mayor = new Date(this.filtros.fecha_final);

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha fin..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }

      if (this.filtros.fecha_inicial && this.filtros.fecha_final) {
        let fecha_menor = new Date(this.filtros.fecha_inicial).getTime();
        let fecha_mayor = new Date(this.filtros.fecha_final).getTime();
        let diff = fecha_mayor - fecha_menor;
        let fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.filtros.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 30 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/funcionariosFrontera/solicitudesInternasExport/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  beforeMount() {
    this.getGerenteArea();
    this.getBloques();
    this.getSitios();
    this.getTipoSolicitudes();
  },
};
</script>
